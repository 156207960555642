import { action, computed, observable } from 'mobx';
import moment from 'moment';
import {
	FormFieldType,
	IBaseApiModel,
	IContact,
	IFormBase,
	IFormField,
	IFormResponse,
	IOperationResult,
	IPageCollectionController,
	IUser,
	PageCollectionController,
	UserSessionContext,
	ViewModel,
	asApiError,
	getEmptyPageControllerResolvedPromise,
} from '../../extViewmodels';
import { FormFieldResolvers } from './FormFieldResolvers';
import { IMeme } from './gamification';
import { ILeadView } from './leads/interfaces';
import { AidaNoteViewModel } from './note';

export enum FieldKey {
	BadInfo = 'BadInfo',
	BadLead = 'BadLead',
	BDUapproved = 'BDUapproved',
	Booked = 'Booked',
	Captive = 'Captive',
	Connected = 'Connected',
	Disconnected = 'Disconnected',
	Duplicate = 'Duplicate',
	Enterprise = 'Enterprise',
	GKBlocked = 'GKBlocked',
	HookRejected = 'HookRejected',
	LeftVoiceMail = 'LeftVoiceMail',
	NoAnswer = 'NoAnswer',
	None = 'None',
	OffVertical = 'OffVertical',
	Other = 'Other',
	PermanentlyClosed = 'PermanentlyClosed',
	QualifiedPitch = 'QualifiedPitch',
	QualityPitch = 'QualityPitch',
	QuickPitch = 'QuickPitch',
	Retired = 'Retired',
	Shortcuts = 'Shortcuts',
	Stumped = 'Stumped',
	TopShortcuts = 'TopShortcuts',
	Vertical = 'Vertical',
	MeetingOutcomes = 'MeetingOutcomes',
	AppointmentConfirmed = 'AppointmentConfirmed',
	AppointmentConfirmationFailedAttempt = 'AppointmentConfirmationFailedAttempt',
	AppointmentCancelled = 'AppointmentCancelled',
	AppointmentRescheduled = 'AppointmentRescheduled',
	/** A "new" demo because the last one did not happen to be used for reschedule calls */
	NewDemo = 'NewDemo',

	/** FE only? */
	Unknown = 'Unknown',
}

export interface IFormFieldOption extends IBaseApiModel {
	name: FieldKey;
	label: string;
	description?: string;
	fieldId: string;
	hexColor?: string;
}

export interface IFormRequest {
	values: Record<string, string>;
	meetingId?: string;
}

type ICustomFormData = Record<string, any>;

export interface IForm extends IFormBase {
	fields: FormFieldViewModel<any>[];
}

export interface IFormFieldConfig {
	baseApiUrl: string;
	saveOnChange?: boolean;
}

export interface IInitiateMeetingRequest {
	companyId: string;
	contactId?: string;
	timeZone?: string;
}

export interface IInitiateSchedulerResponse {
	scheduledMeetingId: string;
	/** Full URL to launch to */
	url: string;
}

export class FormFieldViewModel<T> extends ViewModel {
	@observable protected mField: IFormField<T> = null;
	@observable protected mOptions: IFormFieldOption[] = [];

	@observable protected mPerson: T = null;

	@observable protected mTempValue: T;
	@observable
	protected optionsPageCollectionController: IPageCollectionController<IFormFieldOption>;
	@observable protected mSaveOnChange: boolean;

	@observable protected mSchedulerLink: IInitiateSchedulerResponse;

	@observable public note: AidaNoteViewModel;
	@observable public validationError = '';
	@observable public saving = false;

	private mBaseApiUrl: string;

	constructor(userSession: UserSessionContext, field: IFormField<T>, config: IFormFieldConfig) {
		super(userSession);
		this.mBaseApiUrl = config.baseApiUrl;
		this.mSaveOnChange = !!config.saveOnChange;
		this.mField = field;

		this.optionsPageCollectionController = new PageCollectionController(
			this.userSession.webServiceHelper,
			`${this.mBaseApiUrl}/Field/${this.mField.id}`
		);

		if (this.fieldType === FormFieldType.Note) {
			// potentially initialize with the outcome note?
			this.note = new AidaNoteViewModel(userSession, {
				id: (this.mField.value as unknown as string) || '',
			});
		}
	}

	@computed
	get decimalPlaces() {
		return this.mField.decimalPlaces;
	}

	@computed
	get fieldType() {
		return this.mField.fieldType;
	}

	@computed
	get id() {
		return this.mField.id;
	}

	@computed
	get isOptional() {
		return this.mField.isOptional;
	}

	@computed
	get label() {
		return this.mField.label;
	}

	@computed
	get maxLength() {
		return this.mField.maxLength;
	}

	@computed
	get name() {
		return this.mField.name;
	}

	@computed
	get options() {
		return this.mOptions;
	}

	@computed
	get person() {
		return this.mPerson;
	}

	@computed
	get placeholder() {
		return this.mField.placeHolder;
	}

	@computed
	get tempValue() {
		return this.mTempValue;
	}

	@computed
	get value() {
		return this.mField.value;
	}

	@computed
	get schedulerLink() {
		return this.mSchedulerLink;
	}

	@action
	public setSchedulerLink(meetingId: string) {
		this.mSchedulerLink = {
			scheduledMeetingId: meetingId,
			url: '',
		};
	}

	@action
	public clearSchedulerLink() {
		this.mSchedulerLink = null;
	}

	@computed
	get isHidden() {
		return this.mField.isHidden;
	}

	@action
	public setHidden(hidden: boolean) {
		return (this.mField = {
			...this.mField,
			isHidden: hidden,
		});
	}

	/**
	 * Performs any necessary loading depding on the field type.
	 *
	 * @returns Promise<void>
	 */
	@action
	public load = async () => {
		switch (this.mField.fieldType) {
			case FormFieldType.Option:
			case FormFieldType.MultipleOptions:
				return this.loadOptions();
			case FormFieldType.Date:
			case FormFieldType.DateTime:
				this.validationError = 'Field Required';
				return;
			default:
				return;
		}
	};

	@action
	public reset = () => {
		this.busy = false;

		this.mTempValue = null;
		this.mOptions = [];

		this.mSchedulerLink = null;
	};

	@action
	public set = (val: any) => {
		this.validationError = '';
		this.mTempValue = val;

		if (this.mSaveOnChange) {
			this.mSave();
		}
	};

	@action
	public setPerson = (person: T) => {
		this.set(person);
		this.mPerson = person;
	};

	@action
	public validate = () => {
		// Do not validate hidden fields
		if (this.isHidden) {
			return;
		}

		if (!this.isOptional && !this.tempValue && !this.value) {
			this.validationError = `${this.label} is required`;
		}
	};

	public toJs() {
		return this.mField;
	}

	@action
	public loadOptions = async (search?: string) => {
		if (this.optionsPageCollectionController?.hasAllPages(null, null, { search })) {
			return getEmptyPageControllerResolvedPromise<IFormField<T>>();
		}

		this.busy = true;

		const promise = this.optionsPageCollectionController.getNext(null, null, {
			search,
		});
		promise
			.then(result => {
				this.mOptions = result.fetchedFirstPage
					? result.values
					: result.values.length > 0
						? [...this.mOptions, ...result.values]
						: this.mOptions;
				this.busy = false;
			})
			.catch(() => {
				this.mOptions = [];
				this.busy = false;
			});
		return promise;
	};

	@action
	public loadMeetingLink = async (request: IInitiateMeetingRequest): Promise<IInitiateSchedulerResponse> => {
		this.busy = true;
		// TODO find lib for this
		let url = `lead/${request.companyId}/meetingScheduler?`;
		if (request.contactId) {
			url = url.concat(`contactId=${encodeURIComponent(request.contactId)}`);
		}
		if (request.timeZone) {
			if (request.contactId) {
				url += '&';
			}
			url = url.concat(`timeZone=${encodeURIComponent(request.timeZone)}`);
		}
		const result = await this.userSession.webServiceHelper.callWebServiceAsync<IInitiateSchedulerResponse>(
			this.composeApiUrl({ urlPath: url }),
			'POST'
		);

		if (!result.success) {
			this.busy = false;
			throw result;
		}

		this.mSchedulerLink = result.value;
		this.busy = false;

		return result.value;
	};

	@action
	protected mSave = async () => {
		if (!this.saving) {
			this.saving = true;

			if (this.note) {
				await this.note.save(this.note.toJs());
			}

			this.saving = false;
		}
	};

	@action
	public updateField = (field: IFormField<T>) => {
		this.mField = field;
	};
}

/**
 * Right now the custom form view model is used exclusively for deals. In the future, it should be expanded so that it
 * can be used for any custom forms. Some minor refactoring may be needed, particularly around the base routes. On
 * initialization, load the form fields contained within the custom form
 */
export class CustomFormViewModel extends ViewModel {
	@observable private mForm: IForm = null;
	@observable public lead: ILeadView;
	@observable public loadingForm = false;
	@observable public saving = false;
	@observable public saved = false;

	@observable public customProperties: Record<string, any>;
	private mBaseApiUrl: string;

	constructor(userSession: UserSessionContext, lead: ILeadView, baseApiUrl?: string) {
		super(userSession);
		this.lead = lead;

		this.mBaseApiUrl = baseApiUrl;
	}

	@computed
	get companyId() {
		return this.lead.company?.id;
	}

	@computed
	get dealId() {
		return this.mBaseApiUrl.toLowerCase().split('deal/')[1];
	}

	@computed
	get form() {
		return this.mForm;
	}

	@computed
	get fields() {
		return this.mForm?.fields;
	}

	@action
	public forceSet = (form: IForm) => {
		this.mForm = form;
	};

	public toJs() {
		return this.mForm;
	}

	@action
	public loadForm = async () => {
		if (!this.loadingForm) {
			this.loadingForm = true;

			try {
				const value = await this.userSession.webServiceHelper.callAsync<IFormResponse>(
					this.composeApiUrl({ urlPath: this.mBaseApiUrl }),
					'GET'
				);

				this.mForm = {
					...value,

					fields: value.fields.map(
						(field: IFormField<any>) =>
							new FormFieldViewModel<IFormFieldOption[]>(this.userSession, field, { baseApiUrl: this.mBaseApiUrl })
					),
				};
			} finally {
				this.loadingForm = false;
			}
		}
	};

	@action
	public save = async (customData: ICustomFormData = {}) => {
		if (this.saving) {
			return;
		}
		this.saving = true;
		const formData: IFormRequest = { values: {} };
		await Promise.all(
			this.mForm.fields.map(async f => {
				const field = { ...f.toJs() };
				if (!!f.tempValue && f.tempValue !== field.value) {
					field.value = f.tempValue;
				}
				if (field.fieldType === FormFieldType.Note) {
					try {
						await f.note.save(f.note.toJs());
					} catch (error) {
						this.saved = false;
						this.saving = false;
						throw asApiError(error);
					}
				}

				switch (field.fieldType) {
					case FormFieldType.User:
					case FormFieldType.Contact: {
						const value = (field.value as IFormFieldOption | IUser | IContact)?.id;
						if (value) {
							formData.values[field.id] = value;
						}
						break;
					}
					case FormFieldType.Note: {
						if (f.note?.id) {
							formData.values[field.id] = f.note.id;
						}
						break;
					}
					case FormFieldType.Date:
					case FormFieldType.DateTime: {
						formData.values[field.id] = moment(field.value as Date).format();
						break;
					}
					default: {
						formData.values[field.id] = field.value as string;
						break;
					}
				}
			})
		);

		// if there is a meeting id, use that,
		// otherwise send the demo perform date
		// but only one OR the other, not both
		const meetingSchedulerField = this.mForm.fields.find(
			x => x.name === FormFieldResolvers.getMeetingSchedulerField(this.userSession)
		);

		const demoPerformDateField = this.mForm.fields.find(
			x => x.name === FormFieldResolvers.getDemoPerformDateField(this.userSession)
		);

		// Only validate if demo perform date field is present.
		if (demoPerformDateField) {
			if (meetingSchedulerField?.schedulerLink?.scheduledMeetingId) {
				formData.meetingId = meetingSchedulerField.schedulerLink.scheduledMeetingId;

				delete formData.values[demoPerformDateField.id];
			} else if (demoPerformDateField.tempValue ?? demoPerformDateField.value) {
				formData.values[demoPerformDateField.id] = moment(
					demoPerformDateField.tempValue ?? demoPerformDateField.value
				).format();
				delete formData.meetingId;
			} else {
				this.saved = false;
				this.saving = false;
				throw asApiError(new Error('You must book a meeting or provide a demo perform date'));
			}
		}

		const body = {
			...formData,
			...customData,
			customProperties: this.customProperties,
		};
		const result: IOperationResult<{ meme: IMeme }> = this.dealId
			? await this.userSession.webServiceHelper.callWebServiceAsync(
					this.composeApiUrl({ urlPath: this.mBaseApiUrl }),
					'PUT',
					body
				)
			: await this.userSession.webServiceHelper.callWebServiceAsync<{
					meme: IMeme;
				}>(this.composeApiUrl({ urlPath: this.mBaseApiUrl }), 'POST', body);
		if (!result.success) {
			this.saving = false;
			throw asApiError(result);
		}

		this.saving = false;
		this.saved = true;

		return result.value?.meme || null;
	};

	@action
	public update = (form: IFormResponse, baseUrl: string) => {
		this.mForm = {
			...form,
			fields: this.mapFields(form.fields, baseUrl),
		};
	};

	private mapFields = (fields: IFormField<any>[], baseUrl: string) => {
		return fields.map(field => {
			const existingField = this.fields?.find(f => f.id === field.id);

			if (existingField) {
				existingField.updateField(field);
				return existingField;
			} else {
				return new FormFieldViewModel(this.userSession, field, {
					baseApiUrl: baseUrl,
					saveOnChange: true,
				});
			}
		});
	};
}
