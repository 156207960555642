import * as Api from '@ViewModels';
import { action, computed, observable } from 'mobx';
import { FieldKey } from './form';
import { IMeme } from './gamification';
import { ILeadFollowUp, ISaveOutcomeRequest, LeadServedSource } from './queue';

export enum FollowUpOptionIds {
	ThisAfternoon = 'THISAFTERNOON',
	Monday = 'MONDAY',
	Tomorrow = 'TOMORROW',
	Tuesday = 'TUESDAY',
	Wednesday = 'WEDNESDAY',
	ThreeDays = 'PLUS3DAYS',
	OneWeek = 'PLUS1WEEK',
	TwoWeeks = 'PLUS2WEEKS',
	TomrrowMorning = 'TOMORROWMORNING',
	OneHour = 'ONEHOUR',
	TwoHours = 'TWOHOUR',
	ThreeHours = 'THREEHOUR',
	FiteenMinutes = '15MINUTES',
	ThirtyMinutes = '30MINUTES',
}

export enum PhoneCallStatus {
	Calling = 'calling',
	Connected = 'connected',
	Hangup = 'hangup',
	Ringing = 'ringing',
}

export interface ICallerdId {
	companyId: string;
	companyName: string;
	phoneNumber: string;
	contactId?: string;
	contactName?: string;
}

export enum PhoneCallFilterCriteriaProperty {
	User = 'User',
	Direction = 'Direction',
}

export type IPhoneCallFilterCriteria = Api.IFilterCriteria<PhoneCallFilterCriteriaProperty>;

export interface IPhoneCallItem {
	contacts?: Api.IUser[];
	company?: Api.ICompany;
	phoneCall: Api.IPhoneCall;
}

export enum OutcomeType {
	OutcomeGroup = 'OutcomeGroup',
	PhoneCallOutcome = 'PhoneCallOutcome',
}

export interface IOutcome {
	description?: string;
	hexColor?: string;
	id?: string;
	isShortCut?: boolean;
	/** Is the value to display in the UI !important, this value can change at any time */
	label: string;
	/** Is the value to key off of to identify the outcome specifically !important, this value should never change */
	name: FieldKey;
	points?: number;
	_type: OutcomeType;
}

export interface IPhoneCallOutcome extends IOutcome {
	requireNote?: boolean;
}

export interface IPhoneCallOutcomeGroup extends IOutcome {
	children: IPhoneCallOutcome[];
	hexColor: string;
}

export interface IPhoneCallRemoteResourceEvent extends Api.IBaseApiModel {
	calling?: string;
	connected?: string;
	hangup?: string;
	ringing?: string;
	provider?: string;
	clientType?: string;
}

export class PhoneCallViewModel extends Api.ViewModel {
	@observable private mPhoneCall: Api.IPhoneCall;
	// @ts-ignore
	@observable private mOutcome: IPhoneCallOutcome = null;
	// @ts-ignore
	@observable public status: PhoneCallStatus;
	@observable public isSaving = false;
	// @ts-ignore
	private mCallingTimeout: number;

	constructor(userSession: Api.UserSessionContext, phoneCall: Api.IPhoneCall) {
		super(userSession);
		this.mPhoneCall = phoneCall;
	}

	@computed
	public get id() {
		return this.mPhoneCall.id;
	}

	@computed
	public get outcome() {
		return this.mOutcome;
	}

	@computed
	public get phoneNumber() {
		return this.mPhoneCall.phoneNumber;
	}

	@computed
	public get fromPhoneNumber() {
		return this.mPhoneCall.creatorPhoneNumber;
	}

	@computed
	public get getCallMetadataDisplayString() {
		if (this.id === 'manual') {
			return `Manual Dial`;
		}

		if (!this.phoneNumber || !this.fromPhoneNumber) {
			return null;
		}

		return `Calling ${this.phoneNumber.standard} from ${this.fromPhoneNumber.standard}`;
	}

	public toJs() {
		return this.mPhoneCall;
	}

	@action
	public saveOutcome = async (
		noteId: string,
		followUpDate: FollowUpOptionIds | ILeadFollowUp,
		doNotContact: boolean,
		source: LeadServedSource,
		additionalOptions?: { decisionMaker: string }
	) => {
		if (!this.isSaving) {
			this.isSaving = true;
			const body: ISaveOutcomeRequest = {
				customProperties: additionalOptions,
				doNotContact,
				leadServedSource: source,
				noteId,
				outcomeId: this.outcome.id,
			};

			if (Object.values(FollowUpOptionIds).includes(followUpDate as FollowUpOptionIds)) {
				body.followUpOptionId = followUpDate as FollowUpOptionIds;
			} else if (followUpDate === null) {
				// @ts-ignore
				body.followUpOptionId = null;
			} else if ('scheduled' in (followUpDate as ILeadFollowUp)) {
				const followUpSpecific = followUpDate as ILeadFollowUp;
				body.followUpDate = followUpSpecific.followUpDate;
				body.followUpImportanceLevel = followUpSpecific.importance;
				body.followUpName = followUpSpecific.name;
				body.scheduledFollowUp = followUpSpecific.scheduled;
			}

			const url =
				this.id === 'manual'
					? this.composeApiUrl({ urlPath: `lead/${this.mPhoneCall.companyId}/outcome` })
					: this.composeApiUrl({ urlPath: `phonecall/${this.id}/outcome` });

			const result = await this.userSession.webServiceHelper.callWebServiceAsync<{
				meme: IMeme;
			}>(url, 'POST', body);

			if (!result.success) {
				this.isSaving = false;
				throw Api.asApiError(result);
			}

			this.isSaving = false;
			return result.value?.meme;
		}
	};

	@action
	public setOutcome = (outcome: IPhoneCallOutcome) => {
		this.mOutcome = outcome;
	};

	@action
	public update = (phoneCall: Api.IPhoneCall) => {
		this.mPhoneCall = {
			...this.mPhoneCall,
			...phoneCall,
		};
	};

	@action
	public updateStatus = (event: IPhoneCallRemoteResourceEvent) => {
		const { calling, ringing, connected, hangup } = event;

		// order matters here!
		// event can have some or all of the properties
		// in IPhoneCallRemoteResourceEvent at any given
		// time, so this order should not be modified
		if (hangup) {
			this.status = PhoneCallStatus.Hangup;
			window.clearTimeout(this.mCallingTimeout);
		} else if (connected) {
			this.status = PhoneCallStatus.Connected;
			window.clearTimeout(this.mCallingTimeout);
		} else if (calling) {
			this.status = PhoneCallStatus.Calling;
			// if a call is never connected, we will not receive
			// a hangup event from dialpad. this can cause the ui
			// to be put into an infinite calling state. to prevent
			// we are adding a timeout so if a calling state does
			// not change in 30 seconds, we will automatically mark
			// the call as disconnected
			this.mCallingTimeout = window.setTimeout(() => {
				this.status = PhoneCallStatus.Hangup;
			}, 1000 * 20);
		} else if (ringing) {
			this.status = PhoneCallStatus.Ringing;
		}
	};
}

/** Keep track of the users phone calls they have made (per lead) When going to a new lead, the view model is reset */
export class PhoneCallsViewModel extends Api.ViewModel {
	// @ts-ignore
	@observable private mCurrentCall: PhoneCallViewModel = null;
	@observable private mIncomingCalls: PhoneCallViewModel[] = [];

	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
	}

	@computed
	get incomingCalls() {
		return this.mIncomingCalls;
	}

	set incomingCalls(value: PhoneCallViewModel[]) {
		this.mIncomingCalls = value;
	}

	/*
	 *The call that the user has made, stored until the user saves the outcome/disposition of the call
	 */
	@computed
	get currentCall() {
		return this.mCurrentCall;
	}

	set currentCall(value: PhoneCallViewModel) {
		this.mCurrentCall = value;
	}

	/** Rehydrate a call that has been made, but the page has been refreshed. It must be reloaded from the server */
	@action
	public setExistingCall = (phoneCall: Api.IPhoneCall, companyId: string) => {
		this.mCurrentCall = new PhoneCallViewModel(this.userSession, {
			...phoneCall,
			companyId,
		});
	};

	@action
	public clearIncomingCalls = () => {
		this.mIncomingCalls = [];
	};

	@action
	public clearCurrentCall = () => {
		// @ts-ignore
		this.mCurrentCall = null;
	};

	@action
	public softReset = () => {
		// @ts-ignore
		this.mCurrentCall = null;
		this.mIncomingCalls = [];
	};

	@action
	public reset = () => {
		this.softReset();
	};
}
