import { IPrincipal, IUserReference, UserSessionContext, ViewModel, asApiError } from '@ViewModels';
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { FieldKey } from './form';
import { IMeme } from './gamification';
import { ILeadView, LeadAction } from './leads/interfaces';
import { LeadsViewModel } from './leads/leads';
import { AidaNoteViewModel } from './note';
import { FollowUpOptionIds, IPhoneCallOutcomeGroup } from './phonecall';

export interface ISkipReason {
	id: string;
	name: FieldKey;
	label: string;
	description?: string;
	requireNote?: boolean;
	requireCustomReason?: boolean;
	excludesFromPool?: boolean;
}

export enum LeadServedSource {
	Unspecified = 'Unspecified',
	Rule = 'Rule',
	FollowUp = 'FollowUp',
	AppointmentConfirmation = 'AppointmentConfirmation',
	Search = 'Search',
	IncomingCall = 'IncomingCall',
	CallLog = 'CallLog',
	SharedLink = 'SharedLink',
}

export interface ICreateQueueParams {
	companyId?: string;
	from?: LeadServedSource;
	ruleId?: string;
}

export enum FollowUpImportance {
	Low = 'Low',
	Medium = 'Medium',
	High = 'High',
}

interface ILeadFollowUpRequestBase {
	scheduledFollowUp?: boolean;
	/** Use this to override the date */
	doNotContact?: boolean;
	followUpContactIds?: string[];
	followUpDate?: Date | string;
	followUpImportanceLevel?: FollowUpImportance;
	followUpName?: string;
	followUpOptionId?: string;
	leadServedSource?: LeadServedSource;
}

export interface ISaveOutcomeRequest extends ILeadFollowUpRequestBase {
	customProperties?: Record<string, any>;
	outcomeId?: string;
	noteId?: string;
	leadServedSource?: LeadServedSource;
}

export interface ILeadNextMeeting {
	startDate: string;
}

export interface ILeadLastDeal {
	creator: IPrincipal;
}

export interface ILeadFollowUp {
	id?: string;
	followUpDate?: string;
	contactIds?: string;
	scheduled?: boolean;
	creationDate?: string;
	creatorId?: string;
	creator?: IUserReference;
	name?: string;
	importance?: FollowUpImportance;
}

export type ILeadFollowUpRequest = ILeadFollowUpRequestBase;

export interface ISkipLeadRequest extends ISaveOutcomeRequest {
	phoneCallId?: string;
	skipReason?: FieldKey;
}

export type timezones = string | 'US/Eastern' | 'US/Central' | 'US/Mountain' | 'US/Pacific' | 'US/Alaska' | 'US/Hawaii';

export const getTimezoneAdjustedTime = (day: string | Date, timezone: timezones) => {
	if (!timezone) {
		return;
	}

	const followUpDay = moment(day);

	const timeZoneOffset = moment(day).tz(timezone).utcOffset();
	const localTzOffset = moment(day).utcOffset();

	const miuntesToAddForTimeZone = localTzOffset - timeZoneOffset;

	const adjustedDay = followUpDay.clone().add(miuntesToAddForTimeZone, 'minutes');
	return adjustedDay.toDate();
};

export const getTimezoneUnadjustedTime = (day: string | Date, timezone: timezones) => {
	const followUpDay = moment(day);

	const timeZoneOffset = moment(day).tz(timezone).utcOffset();
	const localTzOffset = moment(day).utcOffset();

	const minutesToSubtractForTimeZone = localTzOffset - timeZoneOffset;

	const adjustedDay = followUpDay.clone().subtract(minutesToSubtractForTimeZone, 'minutes');
	return adjustedDay.toDate();
};

/**
 * The QueueViewModel contains the information necessary to display the lead. For example, outcomes are loaded, lead
 * info, some information about what the user has selected, and some call information is rehydrated here, in case of a
 * user refresh.
 */
export class QueueViewModel extends ViewModel {
	@observable private mLeads: LeadsViewModel = null;

	@observable private mAction: LeadAction;

	@observable private mSkipReason: ISkipReason;

	@observable private mSkipNote: AidaNoteViewModel;
	@observable private mOutcomes: IPhoneCallOutcomeGroup[] = [];

	@observable private mSelectedOutcomeGroup: IPhoneCallOutcomeGroup = null;

	@observable private mShortcuts: IPhoneCallOutcomeGroup = null;

	@observable private mTopShortcuts: IPhoneCallOutcomeGroup = null;
	@observable private mSkipReasons: ISkipReason[] = [];
	@observable private mTempSkipReasons: ISkipReason[] = [];

	@observable private mSearchParams: ICreateQueueParams = null;
	@observable public loadingOutcomes = false;
	@observable public loadingSkipReasons = false;
	@observable public isSavingSkipReason = false;
	@observable public skipReasonSaved = false;

	@observable public hasCalled = false;
	@observable public hasEmailed = false;

	constructor(userSession?: UserSessionContext, createRequest?: ICreateQueueParams) {
		super(userSession);
		this.mLeads = new LeadsViewModel(userSession);
		if (createRequest?.companyId) {
			this.setLead(createRequest);
		}
	}

	@computed
	get hasFulfilledRequirentsToMoveToNextLead() {
		if (this.isDirectAccess) {
			// we do not require anything if you loaded it directly
			return true;
		}

		if (this.lead?.dealForm?.saved || this.lead?.lastDealIsOpen) {
			// deal created/updated
			return true;
		}

		if (this.hasCalled) {
			return true;
		}

		if (!!this.userSession.account.features?.aida?.allowEmailToSkipToNextLead && this.hasEmailed) {
			return true;
		}

		return false;
	}

	@computed
	get companyId() {
		return this.lead?.company?.id;
	}

	@computed
	get isDirectAccess() {
		return this.mSearchParams?.from != null;
	}

	@computed
	get leadServedSource() {
		return this.mSearchParams?.from;
	}

	/**
	 * Identifies if the user has clicked Skip, Next or AddNote (from FAB) triggers conditional logic handled in
	 * CallingCard component
	 */
	@computed
	get action() {
		return this.mAction;
	}

	/**
	 * Separated from other outcomes because it behaves differently than all others. when selected, is actually the skip
	 * event...but is included with outcomes so can be included in other areas of the ui.
	 */
	@computed
	get badLead() {
		return this.outcomes.filter(o => o.name === FieldKey.BadLead)?.[0] || null;
	}

	@computed
	get isBusy() {
		return this.mLeads.isBusy;
	}

	@computed
	get skipNote() {
		return this.mSkipNote;
	}

	/** The currently viewed lead */
	@computed
	get lead() {
		return this.mLeads.lead;
	}

	@computed
	get outcomes() {
		return this.mOutcomes || [];
	}

	@computed
	get outcomeShortcuts() {
		const topShortcuts = this.mTopShortcuts?.children || [];
		const shortcuts = this.mShortcuts?.children || [];
		return [
			...topShortcuts,
			...(this.outcomes.find(x => x.id === FieldKey.MeetingOutcomes)?.children || []),
			...this.outcomes.filter(o => o.isShortCut && o.name !== FieldKey.BadLead),
			...shortcuts,
		];
	}

	@computed
	get selectedOutcomeGroup() {
		return this.mSelectedOutcomeGroup;
	}

	@computed
	get skipReason() {
		return this.mSkipReason;
	}

	@computed
	get skipReasons() {
		return this.mSkipReasons || [];
	}

	@computed
	get tempSkipReasons() {
		return this.mTempSkipReasons || [];
	}

	@action
	public getNextLead = async (params?: { ruleId?: string }) => {
		const ruleId = params?.ruleId;
		this.reset(false);

		return this.mLeads.getNext({ ruleId });
	};

	@action
	public reloadLead = () => {
		this.reset(true);
		return this.mLeads.reloadLead();
	};

	@action
	public reloadLeadWithoutReset = () => {
		return this.mLeads.reloadLead();
	};

	@action
	public setLead = async (search: ICreateQueueParams) => {
		this.reset(true);
		this.mSearchParams = search;

		const lead =
			!search?.companyId || search?.companyId === 'undefined'
				? await this.getNextLead()
				: await this.mLeads.getLeadById(search?.companyId);

		if (lead?.pendingOutcome?.phoneCall) {
			// previous behavior, when there is a call pending outcome
			// we will force the user to enter it and assume that he can just move to the next lead
			// it seems like a bad assumption but leaving it for now
			this.hasCalled = true;
		}
	};

	@action
	public setSkipNote = (note: AidaNoteViewModel) => {
		this.mSkipNote = note;
	};

	@action
	public loadPhoneCallOutcomes = async (companyId: string) => {
		this.loadingOutcomes = true;
		const result = await this.webServiceHelper.callWebServiceAsync<IPhoneCallOutcomeGroup[]>(
			this.composeApiUrl({ urlPath: `lead/${companyId}/PhoneCallOutcome` }),
			'GET'
		);

		if (result.success) {
			const outcomes: IPhoneCallOutcomeGroup[] = [];

			result.value.forEach(group => {
				if (group.name === FieldKey.Shortcuts) {
					this.mShortcuts = group;
				} else if (group.name === FieldKey.TopShortcuts) {
					this.mTopShortcuts = group;
				} else {
					outcomes.push(group);
				}
			});
			this.mOutcomes = outcomes;
			this.loadingOutcomes = false;
		} else {
			this.loadingOutcomes = false;
			throw asApiError(result);
		}
	};

	@action
	public loadSkipReasons = async (companyId: string) => {
		this.loadingSkipReasons = true;
		const result = await this.webServiceHelper.callWebServiceAsync<ISkipReason[]>(
			this.composeApiUrl({ urlPath: `lead/${companyId}/SkipReason` }),
			'GET'
		);

		if (result.success) {
			this.mSkipReasons = result.value.filter(x => x.excludesFromPool === true);

			this.mTempSkipReasons = result.value.filter(x => x.excludesFromPool !== true);
			this.loadingSkipReasons = false;
		} else {
			this.loadingSkipReasons = false;
			throw asApiError(result);
		}
	};

	@action
	public setFollowUp = async (date: Date, name: string, importanceLevel = FollowUpImportance.Low) => {
		const body: ILeadFollowUpRequest = {
			followUpContactIds: this.lead.contacts?.map(x => x.id) || [],
			followUpDate: date,
			followUpImportanceLevel: importanceLevel,
			followUpName: name,
			scheduledFollowUp: true,
		};

		const result = await this.webServiceHelper.callWebServiceAsync<ILeadView>(
			this.composeApiUrl({ urlPath: `lead/${this.lead.company.id}/followUp` }),
			'PUT',
			body
		);

		if (!result.success) {
			throw asApiError(result);
		}

		this.mLeads.setLeadFollowUp(result.value.followUp);

		return result;
	};

	@action
	public deleteFollowUp = async () => {
		const result = await this.webServiceHelper.callWebServiceAsync(
			this.composeApiUrl({ urlPath: `lead/${this.lead.company.id}/followUp` }),
			'DELETE'
		);

		if (!result.success) {
			throw asApiError(result);
		}

		return result;
	};

	@action
	public reset = (reload: boolean) => {
		this.mAction = null;

		this.mSelectedOutcomeGroup = null;
		this.loadingOutcomes = false;
		this.isSavingSkipReason = false;
		this.skipReasonSaved = false;

		this.mSkipReason = null;

		this.mSearchParams = null;

		this.mSkipNote = null;

		if (!reload) {
			// before getting new lead
			this.hasCalled = false;
			this.hasEmailed = false;
		}
	};

	@action
	public saveSkipReason = async (followUpDate: FollowUpOptionIds | ILeadFollowUp, currentCallId?: string) => {
		if (this.skipReason.requireNote && !this.mSkipNote) {
			throw asApiError(new Error('You must provide a note for this skip reason'));
		}

		if (!this.isSavingSkipReason) {
			this.isSavingSkipReason = true;

			const body: ISkipLeadRequest = {
				doNotContact: false, // they will be excluded for being skipped, we don't present this as an option
				leadServedSource: this.leadServedSource,
				outcomeId: this.skipReason.id,

				phoneCallId: currentCallId === 'manual' ? null : currentCallId,
				skipReason: this.skipReason.name,
			};

			if (Object.values(FollowUpOptionIds).includes(followUpDate as FollowUpOptionIds)) {
				body.followUpOptionId = followUpDate as FollowUpOptionIds;
			} else if (followUpDate === null) {
				body.followUpOptionId = null;
			} else if ('scheduled' in (followUpDate as ILeadFollowUp)) {
				const followUpSpecific = followUpDate as ILeadFollowUp;
				body.followUpDate = followUpSpecific.followUpDate;
				body.followUpImportanceLevel = followUpSpecific.importance;
				body.followUpName = followUpSpecific.name;
				body.scheduledFollowUp = followUpSpecific.scheduled;
			}

			if (this.mSkipNote) {
				try {
					await this.mSkipNote.saveLeadNote(this.mSkipNote.toJs(), `lead/${this.lead.company.id}/Note`, null, true);
					body.noteId = this.mSkipNote.id;
				} catch (err) {
					throw asApiError(err);
				}
			}

			const result = await this.userSession.webServiceHelper.callWebServiceAsync<{
				meme: IMeme;
			}>(this.composeApiUrl({ urlPath: `lead/${this.lead.company.id}/skip` }), 'POST', body);

			if (result.success) {
				this.isSavingSkipReason = false;
				this.skipReasonSaved = true;

				return result.value?.meme ?? null;
			} else {
				this.isSavingSkipReason = false;
				throw asApiError(result);
			}
		}
	};

	@action
	public setAction = (leadAction: LeadAction) => {
		this.mAction = leadAction;
	};

	@action
	public setSelectedGroup = (group: IPhoneCallOutcomeGroup) => {
		this.mSelectedOutcomeGroup = group;
	};

	@action
	public setSkipReason = (skipReason: ISkipReason) => {
		this.mSkipReason = skipReason;
	};
}
