import * as Api from '@ViewModels';
import { IContactParticipant } from '@ViewModels';
import { action, computed, observable } from 'mobx';
import { CustomFormViewModel, FormFieldViewModel } from '../form';
import { AidaNoteViewModel } from '../note';
import { FollowUpOptionIds, IOutcome } from '../phonecall';
import { ILeadFollowUp } from '../queue';
import { IFollowUp, ILeadView, INearbyClients } from './interfaces';

/**
 * Contain and then track information about the lead being displayed to the user. Most updates are applied to the lead
 * immediately, and in part instead of relying on reloading the whole lead. Reloading a lead that was already displayed
 * requires some state rehydration, including but not limited to a phone call that was placed before refreshing.
 *
 * The user still needs to be able to save the outcome of that phone call, and will be reminded to do so each time they
 * visit the lead until the outcome is recoreded.
 */
export class LeadViewModel extends Api.ViewModel {
	// @ts-ignore
	@observable public fetchingContacts: boolean;
	// @ts-ignore
	@observable.ref public contacts: Api.ContactViewModel[];
	// @ts-ignore
	@observable public note: AidaNoteViewModel;
	@observable public settingAttribute = false;
	// @ts-ignore
	@observable public decisionMaker: string;
	// @ts-ignore
	@observable public clientsNearMe: INearbyClients = null;

	// @ts-ignore
	@observable private mLead: ILeadView = null;
	// @ts-ignore
	@observable private mCompany: Api.CompanyViewModel = null;
	// @ts-ignore
	@observable private mDecisionMakerIds: string[];
	// @ts-ignore
	@observable private mAttributesForm: CustomFormViewModel = null;
	// @ts-ignore
	@observable private mDealForm: CustomFormViewModel = null;
	@observable private mExecutiveSummary: AidaNoteViewModel;
	// @ts-ignore
	@observable private mFollowUpOptions: IFollowUp;
	// @ts-ignore
	@observable private mFollowUpSelected: FollowUpOptionIds | ILeadFollowUp = null;

	/** Busy states */
	@observable private mDeletingContact = false;
	@observable private mGettingFollowUpOptions = false;
	@observable private mUpdatingContact = false;
	@observable private mSavingExecutiveSummary = false;
	@observable private mSavingTimezone = false;

	private contactsPageCollectionController: Api.IPageCollectionController<Api.IContact>;

	constructor(userSession: Api.UserSessionContext, lead: ILeadView) {
		super(userSession);
		this.mSetLead(lead);
		this.mExecutiveSummary = new AidaNoteViewModel(userSession, lead.executiveSummary);
		if (this.mExecutiveSummary.id) {
			this.mExecutiveSummary.load();
		}
		// @ts-ignore
		this.contactsPageCollectionController = new Api.PageCollectionController(
			userSession.webServiceHelper,
			// @ts-ignore
			`contact/byCompany/${lead.company.id}`
		);
	}

	@computed
	get company() {
		return this.mCompany;
	}

	@computed
	get dealForm() {
		return this.mDealForm;
	}

	@computed
	get executiveSummary() {
		return this.mExecutiveSummary;
	}

	@computed
	get pendingOutcome() {
		return this.mLead?.pendingOutcome;
	}

	@computed
	get lastDeal() {
		return this.mLead?.lastDeal;
	}

	@computed
	get nextMeeting() {
		return this.mLead?.nextMeeting;
	}

	@computed
	get nextMeetingFormattedString() {
		return this.mLead?.nextMeetingFormattedString;
	}

	@computed
	get nextMeetingParticipant(): string {
		// @ts-ignore
		const person: IContactParticipant = this.mLead?.nextMeeting?.participants?.find(
			x => x._type === 'ContactParticipant'
		);
		if (!person) {
			return 'Unknown';
		}

		// @ts-ignore
		return person.firstName;
	}

	@computed
	get previousDeals() {
		return this.mLead?.previousDeals;
	}

	@computed
	get requiredActivity() {
		return this.mLead?.requiredActivity;
	}

	get lastDealIsOpen() {
		return this.mLead?.lastDeal && this.mLead.lastDeal.isOpen;
	}

	@computed
	get followUp() {
		return this.mLead.followUp;
	}

	@action
	public setFollowUp(followUp: ILeadFollowUp) {
		this.mLead = {
			...this.mLead,
			followUp,
		};
	}

	@computed
	public get isBusy() {
		return this.busy || this.loading;
	}

	@computed
	get isUpdating() {
		return this.company.isBusy;
	}

	@computed
	get attributesForm() {
		return this.mAttributesForm;
	}

	@computed
	get statusFlags() {
		return this.mLead.statusFlags;
	}

	@computed
	get providerUrl() {
		return this.mLead.providerUrl;
	}

	@computed
	get providerName() {
		return this.mLead.providerName;
	}

	@computed
	get abbreviatedTimeZone() {
		return this.mLead.abbreviatedTimeZone ?? this.mCompany?.timeZoneAbbr;
	}

	@computed
	get suggestedTelephonyConfiguration() {
		return this.mLead.suggestedTelephonyConfiguration;
	}

	public isDecisionMaker = (contact: Api.ContactViewModel) => {
		return !!(this.mDecisionMakerIds || []).find(id => id === contact.id);
	};

	public isDecisionMakerById = (id: string) => {
		return !!(this.mDecisionMakerIds || []).find(x => x === id);
	};

	@action
	public loadDealForm = (dealId: string) => {
		this.mDealForm = new CustomFormViewModel(
			this.userSession,
			this.mLead,
			// @ts-ignore
			`lead/${this.mLead.company.id}/UpdateDeal/${dealId}`
		);
	};

	@action
	public createDealForm = () => {
		// @ts-ignore
		this.mDealForm = new CustomFormViewModel(this.userSession, this.mLead, `lead/${this.mLead.company.id}/CreateDeal`);
		this.mDealForm.customProperties = { decisionMaker: this.decisionMaker };
	};

	public getClientsNearMe = async () => {
		const result = await this.userSession.webServiceHelper.callWebServiceAsync<INearbyClients>(
			`lead/${this.company?.id}/nearbyClients`,
			'GET'
		);
		if (!result.success) {
			throw Api.asApiError(result);
		}

		// @ts-ignore
		this.clientsNearMe = result.value;
	};

	@action
	public deleteContact = async (contactId: string) => {
		if (!this.mDeletingContact) {
			this.mDeletingContact = true;
			const contact = this.contacts.find(c => c.id === contactId);
			if (contact) {
				const result = await contact.delete();

				// @ts-ignore
				if (result.success) {
					this.contacts = this.contacts.filter(c => c.id !== contactId);
					this.mDeletingContact = false;
				} else {
					this.mDeletingContact = false;
					throw Api.asApiError(result);
				}
			}
		}
	};

	@action
	public getContacts = (sortDescriptor?: Api.ISortDescriptor, pageSize?: number) => {
		if (this.contactsPageCollectionController.hasAllPages(sortDescriptor, pageSize)) {
			return Api.getEmptyPageControllerResolvedPromise<Api.IContact>();
		}

		this.fetchingContacts = true;
		const promise = this.contactsPageCollectionController.getNext(sortDescriptor, pageSize);
		promise
			.then(result => {
				const fetchResults = result.values.map(x => new Api.ContactViewModel(this.mUserSession, x));
				this.contacts = result.fetchedFirstPage
					? fetchResults
					: fetchResults.length > 0
						? [...this.contacts, ...fetchResults]
						: this.contacts;
				this.fetchingContacts = false;
			})
			.catch(() => {
				this.contacts = [];
				this.fetchingContacts = false;
			});
		return promise;
	};

	@action
	public getFollowUp = async (outcome: IOutcome) => {
		if (!this.mGettingFollowUpOptions) {
			this.mGettingFollowUpOptions = true;

			const result = await this.webServiceHelper.callWebServiceAsync<IFollowUp>(
				this.composeApiUrl({ urlPath: `lead/${this.company.id}/PhoneCallOutcome/${outcome.id}/FollowUp` }),
				'GET'
			);

			if (result.success) {
				// @ts-ignore
				this.mFollowUpOptions = result.value;
				this.mGettingFollowUpOptions = false;
			} else {
				this.mGettingFollowUpOptions = false;
				throw Api.asApiError(result);
			}
		}
	};

	@computed
	get followUpOptions() {
		return this.mFollowUpOptions;
	}

	@action
	public clearFollowUpOptions = () => {
		// @ts-ignore
		this.mFollowUpOptions = null;
	};

	@action
	public resetContacts = () => {
		this.fetchingContacts = false;
		this.contacts = [];
		this.contactsPageCollectionController.reset();
	};

	@action
	public resetNote = () => {
		// @ts-ignore
		this.note = null;
	};

	@action
	public saveExecutiveSummary = async (
		note: Api.INote,
		url: string,
		newAttachments?: Api.AttachmentsToBeUploadedViewModel<File>
	) => {
		if (!this.mSavingExecutiveSummary) {
			this.mSavingExecutiveSummary = true;

			// @ts-ignore
			await this.executiveSummary.saveLeadNote(note, url, newAttachments);

			const result = await this.userSession.webServiceHelper.callWebServiceAsync<ILeadView>(
				this.composeApiUrl({ urlPath: `lead/${this.company.id}/ExecutiveSummary` }),
				'PUT',
				{ executiveSummaryNoteId: this.executiveSummary.id }
			);

			if (result.success) {
				// @ts-ignore
				this.mSetLead(result.value);
				this.mSavingExecutiveSummary = false;
			} else {
				this.mSavingExecutiveSummary = false;
				throw Api.asApiError(result);
			}
		}
	};

	@action
	public setAttribute = async (attribute: FormFieldViewModel<any>, optionId: string) => {
		if (!this.settingAttribute) {
			this.settingAttribute = true;

			const result = await this.userSession.webServiceHelper.callWebServiceAsync<ILeadView>(
				this.composeApiUrl({ urlPath: `lead/${this.company.id}/Lead/Field/${attribute.id}` }),
				'PUT',
				{ value: optionId }
			);

			if (result.success) {
				// @ts-ignore
				this.mSetLead(result.value);
				this.settingAttribute = false;
			} else {
				this.settingAttribute = false;
				throw Api.asApiError(result);
			}
		}
	};

	@action
	public setSelectedFollowUp = (followUp: FollowUpOptionIds | ILeadFollowUp) => {
		this.mFollowUpSelected = followUp;
	};

	@computed
	public get selectedFollowUp() {
		return this.mFollowUpSelected;
	}

	@action
	public setTimezone = async (timeZone: string) => {
		if (!this.mSavingTimezone) {
			this.mSavingTimezone = true;

			const result = await this.userSession.webServiceHelper.callWebServiceAsync<any>(
				this.composeApiUrl({
					queryParams: { timeZone },
					urlPath: `lead/${this.company.id}/TimeZone`,
				}),
				'PUT'
			);

			if (result.success) {
				this.mCompany.setTimezone(timeZone);
				this.mSavingTimezone = false;
			} else {
				this.mSavingTimezone = false;
				throw Api.asApiError(result);
			}
		}
	};

	@action
	public toggleDecisionMaker = async (contact?: Api.ContactViewModel) => {
		if (!this.mUpdatingContact) {
			this.mUpdatingContact = true;
			let contactIds = [...this.mDecisionMakerIds];
			// @ts-ignore
			if (this.isDecisionMaker(contact)) {
				// @ts-ignore
				contactIds = contactIds.filter(id => id !== contact.id);
			} else {
				// @ts-ignore
				// @ts-ignore
				contactIds.push(contact.id);
			}

			const result = await this.userSession.webServiceHelper.callWebServiceAsync<ILeadView>(
				this.composeApiUrl({ urlPath: `lead/${this.company.id}/DecisionMakers` }),
				'PUT',
				{ contactIds }
			);

			if (result.success) {
				// @ts-ignore
				this.mSetLead(result.value);
				this.mUpdatingContact = false;
			} else {
				this.mUpdatingContact = false;
				throw Api.asApiError(result);
			}
		}
	};

	@action
	public update = async (updatedLead: ILeadView) => {
		try {
			// @ts-ignore
			await this.company.update(updatedLead.company);
		} catch (err) {
			throw Api.asApiError(err);
		}
	};

	@action
	private mSetLead = (lead: ILeadView) => {
		this.mLead = lead;
		// @ts-ignore
		this.mCompany = new Api.CompanyViewModel(this.userSession, lead.company);
		this.mDecisionMakerIds = lead.decisionMakersContactIds || [];

		// @ts-ignore
		const baseUrl = `lead/${this.mLead.company.id}/Lead`;
		if (!this.mAttributesForm) {
			this.mAttributesForm = new CustomFormViewModel(this.userSession, this.mLead, baseUrl);
		}

		// @ts-ignore
		this.mAttributesForm.update(lead.attributesForm, baseUrl);
	};
}
